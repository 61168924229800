import React, { FC } from 'react';
import { LoadingComponentProps } from 'react-loadable';

const Loading: FC<LoadingComponentProps> = (props) => {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>Loader timed out!</div>;
    } else if (props.pastDelay) {
      return <div>Loading......</div>;
    } else {
      return <div>Loading...</div>;
    }
  } else if (props.error) {
    return <div>Error! Component failed to load</div>;
  } else {
    return null;
  }
}

export default Loading;
