import React, { FC, ImgHTMLAttributes } from 'react';

interface Prop extends ImgHTMLAttributes<any> {
  src?: never
  source: string | string[]
}

const Img: FC<Prop> = ({source, ...props}) => {
  const isStringSrc = typeof source === "string"
  const imgSrc = isStringSrc ? source as string : source[0]
  let srcSet: string | undefined
  if (!isStringSrc) {
    srcSet = (source as string[]).map((s, i) => `${s} ${i+1}x`).join(',')
  }

  return (
    <img src={imgSrc as string} srcSet={srcSet} alt={imgSrc} {...props}/>
  )
}

export default Img