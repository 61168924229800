import Loading from 'components/Loading'
import Loadable from 'react-loadable'

const Loadabler = (loader: () => any) => Loadable({
  loader,
  loading: Loading,
})

export { default as NotFound } from './NotFound'

export const Home = Loadabler(() => import('./Home'))
export const About = {
  Index: Loadabler(() => import('./About')),
  Main: Loadabler(() => import('./About/IndexPage')),
  Consultants: Loadabler(() => import('./About/Consultants')),
}
export const Users = {
  Index: Loadabler(() => import('./Users/IndexPage')),
  SignIn: Loadabler(() => import('./Users/SignIn')),
  SignUp: Loadabler(() => import('./Users/SignUp')),
  Username: Loadabler(() => import('./Users/Username')),
  Password: {
    New: Loadabler(() => import('./Users/Password/New')),
    Edit: Loadabler(() => import('./Users/Password/Edit')),
  },
}
export const Consults = {
  Index: Loadabler(() => import('./Consults')),
  Show: Loadabler(() => import('./Consults/Show')),
  Voice: {
    Index: Loadabler(() => import('./Consults/Voice/IndexPage')),
    New: Loadabler(() => import('./Consults/Voice/New')),
  },
  Text: {
    Index: Loadabler(() => import('./Consults/Text/IndexPage')),
    New: Loadabler(() => import('./Consults/Text/New')),
  },
  Edit: Loadabler(() => import('./Consults/Edit')),
}
export const Columns = {
  Index: Loadabler(() => import('./Columns')),
  First: Loadabler(() => import('./Columns/First')),
  Common: {
    Index: Loadabler(() => import('./Columns/Common/IndexPage')),
    Show: Loadabler(() => import('./Columns/Common/Show')),
  },
  Client: {
    Index: Loadabler(() => import('./Columns/Client/IndexPage')),
    Show: Loadabler(() => import('./Columns/Client/Show')),
  },
}
export const Reviews = {
  Index: Loadabler(() => import('./Reviews')),
  Common: {
    Index: Loadabler(() => import('./Reviews/Common/IndexPage')),
    Show: Loadabler(() => import('./Reviews/Common/Show')),
  },
  Best: {
    Index: Loadabler(() => import('./Reviews/Best/IndexPage')),
    Show: Loadabler(() => import('./Reviews/Best/Show')),
  },
  New: Loadabler(() => import('./Reviews/New')),
}
export const Service = {
  Index: Loadabler(() => import('./Service')),
  FAQ: Loadabler(() => import('./Service/Faq')),
  Contact: Loadabler(() => import('./Service/Contact')),
}
export const Mypage = {
  Index: Loadabler(() => import('./Mypage')),
  Edit: Loadabler(() => import('./Mypage/Edit')),
  Articles: Loadabler(() => import('./Mypage/Articles')),
}
export const Intra = {
  Index: Loadabler(() => import('./Intra')),
  Secrets: {
    Index: Loadabler(() => import('./Intra/Secrets/IndexPage')),
    Show: Loadabler(() => import('./Intra/Secrets/Show')),
    New: Loadabler(() => import('./Intra/Secrets/New')),
  },
}
export const Terms = {
  Service: Loadabler(() => import('./Terms/Service')),
  Privacy: Loadabler(() => import('./Terms/Privacy')),
}
