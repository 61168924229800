import React, { Component, Fragment, FC, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router'
import { duration } from 'moment';
import { Location } from 'history';

interface Props extends RouteComponentProps {
  drawed: boolean
  width?: string
  duration?: string
  onClose?: (location: Location) => any
}

const Drawer: FC<Props> = ({width = "300px", duration = "0.3s", drawed, onClose, location, children}) => {
  useEffect(() => {
    onClose && onClose(location)
  }, [location.pathname])

  return (
    <>
      <Overlay
        drawed={drawed}
        duration={duration}
        onClick={() => onClose && onClose(location)}
      />
      <DrawerContainer
        drawed={drawed}
        duration={duration}
        width={width}
      >
        {children}
      </DrawerContainer>
    </>
  )
}

const DrawerContainer = styled('div')<{width: string, duration: string, drawed: boolean}>`
display: block;
overflow-y: scroll;
position: fixed;
top: 0;
bottom: 0;
left: ${({width}) => `-${width}`};
width: ${({width}) => width};
border-right: solid 1px #ddd;
background-color: #fff;
z-index: 1000;
transition: all ${({duration}) => duration};
left: ${({drawed, width}) => drawed ? 0 : -width};
`;

const Overlay = styled('a')<{duration: string, drawed: boolean}>`
position: fixed;
display: flex;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0,0,0,0.4);
transition: opacity ${({duration}) => duration}, z-index 0s linear ${({duration, drawed}) => drawed ? "0s" : duration};
opacity: ${({drawed}) => drawed ? 1 : 0};
z-index: ${({drawed}) => drawed ? 1000 : -1000};
`;

export default withRouter(Drawer);