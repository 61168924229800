import { css, CSSObject, SimpleInterpolation, FlattenSimpleInterpolation } from 'styled-components'

const sizes = {
  mobile: 992,
}
const query = {
  desktop: `min-width: ${sizes.mobile}px`,
  mobile: `max-width: ${sizes.mobile-1}px`,
}

type mediaMixin = (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation

export const media: {
  desktop: mediaMixin,
  mobile: mediaMixin,
} = {
  desktop: (...args) => css`
    @media only screen and (${query.desktop}) {
      ${css(...args)}
    }
  `,
  mobile: (...args) => css`
    @media only screen and (${query.mobile}) {
      ${css(...args)}
    }
  `,
};

export { query };