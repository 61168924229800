import React, { FC } from 'react'
import { connect } from 'react-redux'
import { StoreState } from 'redux/modules'
import { signOut } from 'redux/modules/auth'
import styled from 'styled-components'
import * as colors from 'config/colors'
import { grey2 } from 'config/colors'
import ALink from 'components/ALink'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { Location } from 'history'
import Link from './Link'

const MobileScreen = require('react-responsive-redux').MobileScreen
const DesktopScreen = require('react-responsive-redux').DesktopScreen

interface Prop extends OwnProp {
  userSignedIn: boolean,
  signOut: () => void,
  location: Location,
}

interface OwnProp {
  onClick?: () => any,
}

const DesktopNavUtils: FC<Prop> = ({userSignedIn, signOut, location, onClick}) => {
  const from = location.pathname + location.search

  const onSignOut = async () => {
    await signOut();
    window.alert("로그아웃 되었습니다.");
  }
  const toSignIn = {
    pathname: "/users/sign_in",
    state: { from }
  };
  const toSignUp = {
    pathname: "/users/sign_up",
    state: { from }
  };

  return (
    <>
      <DesktopScreen>
        {userSignedIn ? (
          <>
            <Button as="a" onClick={onSignOut}>
              로그아웃
            </Button>
            <Button to="/mypage/edit" solid="true">
              마이페이지
            </Button>
          </>
        ) : (
          <>
            <Button to={toSignIn}>로그인</Button>
            <Button to={toSignUp} solid="true">
              간편가입
            </Button>
          </>
        )}
        <Favorite
          onClick={() => {
            const isMac = /(Mac)/i.test(navigator.platform);
            window.alert(
              `${
                isMac ? "Cmd+d" : "Ctrl+d"
              } 키를 눌러 즐겨찾기에 등록할 수 있습니다.`
            );
          }}
        >
          즐겨찾기
        </Favorite>
      </DesktopScreen>
      <MobileScreen>
        {userSignedIn ? (
          <>
            <MobileLinkA onClick={onSignOut}>로그아웃</MobileLinkA>
            <Link to="/mypage/edit" onClick={onClick}>마이페이지</Link>
          </>
        ) : (
          <>
            <Link to={toSignIn} onClick={onClick}>로그인</Link>
          </>
        )}
      </MobileScreen>
    </>
  );
}

const Button = styled(ALink)`
font-size: 16px;
color: ${({ solid }) => solid ? "#fff" : colors.blue};
text-decoration: none;
border: solid 1px ${colors.blue};
border-radius: 5px;
padding: 8px 0;
width: 86px;
text-align: center;
margin: 0 5px;
cursor: pointer;
background-color: ${({ solid }) => solid ? colors.blue : "#fff"};
`
const Favorite = styled.a`
font-size: 15px;
color: ${colors.blue};
text-decoration: none;
margin-left: 4vw;
cursor: pointer;
`
const MobileLinkA = styled.a`
height: 54px;
font-size: 17px;
display: flex;
align-items: center;
padding-left: 1rem;
position: relative;
color: #000;
border-bottom: solid 1px ${grey2};
`
export default compose<FC<OwnProp>>(
  withRouter,
  connect((state: StoreState) => ({
    userSignedIn: state.auth.userSignedIn,
  }), ({ signOut }))
)(DesktopNavUtils)
