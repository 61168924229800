import { blue } from 'config/colors';
import { History } from 'history';
import React, { CSSProperties, FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { fadeIn } from 'styles/keyframes';

export interface SubLink {
  label: string
  to: History.LocationDescriptor
}

interface Props {
  links: SubLink[]
  visible?: boolean
  style?: CSSProperties
}

const NavSubLinks: FC<Props> = ({links, visible = false, style}) => {
  return visible ? (
    <Container style={style}>
      {links.map(({label, to}) => (
        <NavLink to={to} key={label}>{label}</NavLink>
      ))}
    </Container>
  ) : null
}

const Container = styled.div`
position: absolute;
background-color: #fff;
min-width: 154px;
padding-top: 20px;
top: 68px;
left: -20px;
z-index: 100;
white-space: nowrap;
animation: ${fadeIn} .1s linear;
> a {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  margin-bottom: 20px;
  padding: 0 20px;
  transition: all .2s;
  line-height: 1.5;
  &:hover {
    color: ${blue};
  }
}
`

export default NavSubLinks