import "@babel/polyfill"
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

import './index.css';

import App from 'client/render';
import * as serviceWorker from './serviceWorker';

Loadable.preloadReady().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
  // if (process.env.NODE_ENV === 'development') {
  //   ReactDOM.render(<App />, document.getElementById('root'));
  // } else {
  //   ReactDOM.hydrate(<App />, document.getElementById('root'));
  // }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
