import { combineReducers } from 'redux'
import { penderReducer, PenderState } from 'redux-pender'
import articles, { ArticleState } from './articles'
import auth, { AuthState } from './auth'
import consultants, { ConsultantState } from './consultants'
import firstColumn, { FirstColumnState } from './firstColumn'
import faq, { FaqState } from './faq'
import { RecordOf } from 'immutable'
import settings, { SettingState } from "./settings"
//import { reducer as formReducer } from 'redux-form'

const responsiveReducer = require('react-responsive-redux').reducer

export interface StoreState {
  pender: PenderState,
  responsive: any,
  auth: RecordOf<AuthState>,
  articles: RecordOf<ArticleState>,
  consultants: RecordOf<ConsultantState>,
  firstColumn: RecordOf<FirstColumnState>,
  faq: RecordOf<FaqState>,
  settings: SettingState,
}

export default combineReducers({
  pender: penderReducer,
  responsive: responsiveReducer,
  //form: formReducer,
  auth,
  articles,
  consultants,
  firstColumn,
  faq,
  settings,
})
