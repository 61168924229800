import { Href, LocationDescriptor } from "history";
import React, { FC } from 'react';
import { Link } from "react-router-dom";

interface Prop {
  to?: LocationDescriptor,
  href?: Href,
  [props: string]: any
}

const ALink: FC<Prop> = ({to, href, ...props}) => {
  if (!to) {
    return <a href={href} {...props}/>
  } else if (!href) {
    return <Link to={to!} {...props}/>
  } else {
    return <span {...props}/>
  }
}

export default ALink