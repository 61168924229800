import * as React from "react"
import { FC, RefObject } from "react"
import styled from "styled-components"
import { media } from "../styles/mixins"

interface Props {
  top: RefObject<any>
  bottom: RefObject<any>
}

const ScrollNav: FC<Props> = ({ top, bottom }) => {
  const scrollToComponent = require('react-scroll-to-component')

  return (
    <Container>
      <a onClick={() => {
        scrollToComponent(top.current)
      }}>▼</a>
      <a onClick={() => {
        scrollToComponent(bottom.current)
      }}>▼</a>
    </Container>
  )
}

const Container = styled.div`
position: fixed;
right: 10px;
bottom: 10px;
${media.desktop`
display: none;
`}
a {
  font-size: 12px;
  color: #fff;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.5);
  border-radius: 5px;
  &:first-of-type {
    transform: rotate(180deg);
    margin-bottom: 6px;
  }
}
`

export default ScrollNav
