import axios from 'axios';
import { CONSULTANTS, HOST } from 'config/api';
import { List, Record } from 'immutable';
import { Action, createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import * as Types from 'redux/actionTypes/consultants';

export interface Consultant {
  _id: string
  name: string
  summary: string
  description: string
  position: string
  more?: string
}
export interface ConsultantState {
  list: List<Consultant>
}
const ConsultantStateRecord = Record<ConsultantState>({
  list: List([])
})

export const load = createAction(Types.LOAD, () => axios.get(HOST+CONSULTANTS.COLLECTIONS))

const initialState = ConsultantStateRecord()

export default handleActions({
  ...pender({
    type: Types.LOAD,
    onSuccess: (state: Record<ConsultantState>, action: Action<{data: Consultant[]}>) => state.set('list', List(action.payload.data)),
  }),
}, initialState)
