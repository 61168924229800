// import { fromJS } from 'immutable';
import configureStore from './configureStore';

// interface ExtWindow extends Window {
//   __PRELOADED_STATE__?: any,
// }

// const extWindow: ExtWindow | null = typeof window === 'undefined' ? null : window

// const convertFromJS = (key: string) => {
//   return fromJS(extWindow && extWindow.__PRELOADED_STATE__ ? extWindow.__PRELOADED_STATE__[key] : undefined);
// }

// TODO: check SSR
export default configureStore();

