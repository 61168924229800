import { Path } from 'history';

export { HOST } from 'config/constants';

export const BEST_REVIEWS = {
  COLLECTIONS: '/best_reviews',
  MEMBER: '/best_reviews/:id',
}
export const CLIENT_COLUMNS = {
  COLLECTIONS: '/client_columns',
  MEMBER: '/client_columns/:id',
}
export const COMMON_COLUMNS = {
  COLLECTIONS: '/common_columns',
  MEMBER: '/common_columns/:id',
}
export const CONSULT_REQUESTS = {
  COLLECTIONS: '/consult_requests',
  MEMBER: '/consult_requests/:id',
}
export const REVIEWS = {
  COLLECTIONS: '/reviews',
  MEMBER: '/reviews/:id',
}
export const SECRETS = {
  COLLECTIONS: '/secret_articles',
  MEMBER: '/secret_articles/:id',
}
export const AUTH = {
  COLLECTIONS: "/auth",
  SIGN_IN: "/auth/sign_in",
  SIGN_OUT: "/auth/sign_out",
  VALIDATE: "/auth/validate",
  ARTICLES: "/auth/articles",
  EMAIL: "/auth/email",
  NICKNAME: "/auth/nickname",
  USERNAME: "/auth/username",
  SEARCH: "/auth/search",
  PASSWORD: "/auth/password",
}
export const CONSULTANTS = {
  COLLECTIONS: '/consultants',
}
export const FIRST_COLUMNS = {
  COLLECTIONS: '/first_columns',
}
export const FAQ = {
  COLLECTIONS: '/faq',
}
export const CONTACT: string = "/contact"

export const SECRET_CATEGORIES = {
  COLLECTIONS: '/secret_categories',
}
export const ADMIN = {
  USER_META: '/admin/user_meta/:id',
}
export const COMMENTS = {
  COLLECTIONS: '/comments',
}

export const SETTINGS = {
  COLLECTIONS: '/settings',
}