import axios from 'axios'
import { BEST_REVIEWS, CLIENT_COLUMNS, COMMON_COLUMNS, CONSULT_REQUESTS, HOST, REVIEWS, SECRETS } from 'config/api'
import { List, Record, RecordOf } from 'immutable'
import { ActionMeta, createAction, handleActions, Action } from 'redux-actions'
import { pender } from 'redux-pender'
import * as Types from 'redux/actionTypes/articles'
import * as CommentType from 'redux/actionTypes/comments'
import { Comment } from './comments'
import { toUnderscore } from 'utils/string'

interface AttachedFile {
  url: string
}

export interface ArticleItem {
  _id: string
  title: string
  createdAt: string
  num: number
  summary?: string
  hit?: number
  writer_name?: string
  writer_id?: string
  writer_level?: number
  notice?: boolean
  category?: string
  year?: number
  hot?: boolean
  commentsCount?: number
}

export interface ArticleList {
  docs: List<ArticleItem>
  total?: number
  limit?: number
  page?: number
  pages?: number
}

const ArticleListRecord = Record<ArticleList>({
  docs: List([]),
  total: undefined,
  limit: undefined,
  page: 1,
  pages: undefined,
})

interface NearArticle {
  _id: string
  _type?: string
  title: string
  num: number
}

export interface ArticleShow {
  _id: string
  _type?: string
  title: string
  content: string
  createdAt: string
  writer_name?: string
  writer_id?: string
  writer_level?: number
  attached_file?: AttachedFile
  comments?: Comment[]
  notice?: boolean
  prevArticle?: NearArticle
  nextArticle?: NearArticle
  termRead?: boolean;
  request_type?: string;
  year?: number;
}

const ArticleShowRecord = Record<ArticleShow>({
  _id: "",
  _type: "",
  title: "",
  content: "",
  createdAt: "",
  writer_name: "",
  writer_id: "",
  writer_level: undefined,
  attached_file: undefined,
  comments: undefined,
  notice: undefined,
  prevArticle: undefined,
  nextArticle: undefined,
  termRead: undefined,
  request_type: undefined,
  year: undefined,
});

export interface ArticleResource {
  list: ArticleList
  show: RecordOf<ArticleShow> | null
}

const ArticleRecord = Record<ArticleResource>({
  list: ArticleListRecord(),
  show: null,
})

export interface ArticleState {
  best_reviews: ArticleResource
  client_columns: ArticleResource
  common_columns: ArticleResource
  consult_requests: ArticleResource
  reviews: ArticleResource
  secrets: ArticleResource
}

const ArticleStateRecord = Record<ArticleState>({
  best_reviews: ArticleRecord(),
  client_columns: ArticleRecord(),
  common_columns: ArticleRecord(),
  consult_requests: ArticleRecord(),
  reviews: ArticleRecord(),
  secrets: ArticleRecord(),
})

export enum ResourceName {
  best_reviews = 'BEST_REVIEWS',
  client_columns = 'CLIENT_COLUMNS',
  common_columns = 'COMMON_COLUMNS',
  consult_requests = 'CONSULT_REQUESTS',
  reviews = 'REVIEWS',
  secrets = 'SECRETS',
}

const API_URL = {
  BEST_REVIEWS,
  CLIENT_COLUMNS,
  COMMON_COLUMNS,
  CONSULT_REQUESTS,
  REVIEWS,
  SECRETS,
}

export const load = createAction(
  Types.LOAD,
  (resource: ResourceName, paramsString: string = "") => axios.get(HOST + API_URL[resource].COLLECTIONS + paramsString),
  (resource: ResourceName) => ({ resource }),
)
export const show = createAction(
  Types.SHOW,
  (resource: ResourceName, id: string) => axios.get(HOST + API_URL[resource].MEMBER.replace(':id', id)),
  (resource: ResourceName, id: string) => ({ resource, id }),
)
export const create = createAction(
  Types.CREATE,
  (resource: ResourceName, data: FormData) => axios.post(HOST + API_URL[resource].COLLECTIONS, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  (resource: ResourceName, id: string) => ({ resource, id }),
)
export const update = createAction(
  Types.UPDATE,
  (resource: ResourceName, id: string, data: FormData) => axios.put(
    HOST + API_URL[resource].MEMBER.replace(':id', id),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ),
)
export const destroy = createAction(
  Types.DESTROY,
  (resource: ResourceName, id: string) => axios.delete(
    HOST + API_URL[resource].MEMBER.replace(':id', id),
  ),
  (resource: ResourceName, id: string) => ({ resource, id }),
)

const initialState = ArticleStateRecord()

export default handleActions({
    ...pender({
      type: Types.LOAD,
      onSuccess: (state: Record<ArticleState>, action: ActionMeta<{ data: ArticleList }, { resource: ResourceName }>) => state.setIn([action.meta.resource.toLowerCase(), 'list'], ArticleListRecord(action.payload.data)),
    }),
    ...pender({
      type: Types.SHOW,
      onPending: (state: Record<ArticleState>, action: ActionMeta<any, { resource: ResourceName, id: string }>) => {
        const prevId = state.getIn(['show', '_id'])
        if (prevId && prevId !== action.meta.id) {
          return state.setIn([action.meta.resource.toLocaleLowerCase(), 'show'], null)
        } else {
          return state
        }
      },
      onSuccess: (state: Record<ArticleState>, action: ActionMeta<{ data: ArticleShow }, { resource: ResourceName, id: string }>) => state.setIn([action.meta.resource.toLowerCase(), 'show'], ArticleShowRecord(action.payload.data)),
    }),
    ...pender({
      type: Types.UPDATE,
      onSuccess: (state: Record<ArticleState>, action: ActionMeta<{ data: ArticleShow }, { resource: ResourceName, id: string }>) => state.setIn([action.meta.resource.toLowerCase(), 'show'], ArticleShowRecord(action.payload.data)),
    }),
    ...pender({
      type: Types.DESTROY,
      onSuccess: (state: Record<ArticleState>, action: ActionMeta<{}, { resource: ResourceName, id: string }>) => {
        const resourceName = action.meta.resource.toLowerCase() as ResourceName
        return state.setIn([resourceName, 'show'], null)
      },
    }),
    [`${CommentType.CREATE}_SUCCESS`]:
      (state, action: ActionMeta<{ data: Comment }, { resource: ResourceName }>) => {
        const resourceName = `${toUnderscore(action.meta.resource)}s`
        const comments = [...state.getIn([resourceName, 'show', 'comments']), action.payload.data]

        return state.setIn([resourceName, 'show', 'comments'], comments)
      },
  },
  initialState,
)

