import { grey, grey2 } from 'config/colors'
import React, { FC, useState } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'
import NavSubLinks, { SubLink } from './SubLinks'
import { media } from 'styles/mixins'
import Collapsible from 'react-collapsible'

interface Props extends NavLinkProps {
  sublinks?: SubLink[]
}

const MobileScreen = require('react-responsive-redux').MobileScreen
const DesktopScreen = require('react-responsive-redux').DesktopScreen

const NavLinkButton: FC<Props> = ({ sublinks, onClick, ...props }) => {
  const [subVisible, setSubVisible] = useState(false)

  return (
    <>
      <Container onMouseEnter={() => setSubVisible(true)} onMouseLeave={() => setSubVisible(false)}>
        <Link onClick={onClick} {...props} />
        {sublinks && sublinks.length > 0 && (
          <NavSubLinks links={sublinks} visible={subVisible}/>
        )}
      </Container>
      <ContainerM>
        {sublinks ? (
          <Collapsible
            transitionTime={130}
            trigger={(
              <Trigger>{props.children}</Trigger>
            )}
          >
            {sublinks && sublinks.length > 0 && (
              <MobileSubLinkContainer>
                {sublinks.map((sublink) => (
                  <Link key={sublink.label} onClick={onClick} to={sublink.to}> - {sublink.label}</Link>
                ))}
              </MobileSubLinkContainer>
            )}
          </Collapsible>
        ) : (
          <Trigger as={NavLink} withoutcaret="true" onClick={onClick} {...props}/>
        )}
      </ContainerM>
    </>
  )
}

const Container = styled(DesktopScreen)`
align-self: stretch;
margin-right: 2rem;
justify-content: center;
position: relative;
`
const ContainerM = styled(MobileScreen)`
border-bottom: solid 1px ${grey2};
`
export const Link = styled(NavLink)`
color: #000;
font-size: 16px;
text-decoration: none;
${media.mobile`
padding: 10px 0;
color: #5a5a5a;
`}
`
const Trigger = styled.a<{ withoutcaret?: string }>`
height: 54px;
font-size: 17px;
display: flex;
align-items: center;
padding-left: 1rem;
position: relative;
color: #000;
${({ withoutcaret }) => withoutcaret === "true" ? "" : `
&::after {
  position: absolute;
  content: "▼";
  right: 8px;
  font-size: 8px;
  color: ${grey};
}
`}
`
const MobileSubLinkContainer = styled.div`
padding-left: 36px;
padding-bottom: 10px;
`
export default NavLinkButton
