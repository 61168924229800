import { createStore, applyMiddleware, compose, DeepPartial } from 'redux'
import penderMiddleware from 'redux-pender';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';

import modules, { StoreState } from './modules';

interface ExtWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any
}

const isDevelopment = process.env.NODE_ENV === 'development';
const middlewares = [
  penderMiddleware(),
  ReduxThunk,
];
if (isDevelopment) middlewares.push(createLogger({collapsed: true}));

let composeEnhancers = compose

if (isDevelopment) {
  const extWindow: ExtWindow = window
  composeEnhancers = extWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const configureStore = (initialState?: DeepPartial<StoreState>) => {
  const store = createStore(modules, initialState, composeEnhancers(
    applyMiddleware(...middlewares)
  ));

  return store;
}

export default configureStore;
