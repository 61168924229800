import axios from 'axios';
import { AUTH, HOST } from 'config/api';
import { Record, RecordOf } from 'immutable';
import { Action, createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import * as Types from 'redux/actionTypes/auth';
import { deleteAuth, tokenHeaderName } from 'utils/axiauth'
import cookie from "cookie"

export interface SignUpData {
  nickname: string
  username: string
  password: string
  password_confirmation: string
  email: string
}
export interface SignInData {
  username: string
  password: string
}
export interface UserUpdateData {
  password?: string
  passwordConfirmation?: string
  email?: string
}
export interface User {
  _id: string
  user_level: number
  nickname: string
  username: string
  email: string
}
const UserRecord = Record<User>({
  _id: '',
  user_level: 30,
  nickname: '',
  username: '',
  email: '',
})
export interface AuthState {
  userSignedIn: boolean
  resource: RecordOf<User> | null
}
const AuthStateRecord = Record<AuthState>({
  userSignedIn: false,
  resource: null,
})

export const signUp = createAction(Types.SIGN_UP, (data: SignUpData) => axios.post(HOST+AUTH.COLLECTIONS, data))
export const signIn = createAction(Types.SIGN_IN, ({username, password}: SignInData) => axios.post(HOST+AUTH.SIGN_IN, {
  username, password
}))
export const signOut = createAction(Types.SIGN_OUT, () => axios.delete(HOST+AUTH.SIGN_OUT))
export const update = createAction(Types.UPDATE, (data: UserUpdateData) => axios.put(HOST+AUTH.COLLECTIONS, data))
export const validate = createAction(Types.VALIDATE, () => axios.get(HOST+AUTH.VALIDATE))

const initialState = AuthStateRecord()

const onSuccess = (state: Record<AuthState>, action: Action<{data: User}>) => state.set('userSignedIn', true).set('resource', UserRecord(action.payload.data))
const onFailure = (state: Record<AuthState>) => state.set('userSignedIn', false).set('resource', null)
export default handleActions({
  ...pender({
    type: Types.SIGN_IN,
    onSuccess,
    onFailure,
  }),
  ...pender({
    type: Types.VALIDATE,
    onSuccess,
    onFailure,
  }),
  ...pender({
    type: Types.SIGN_OUT,
    onSuccess: (state: Record<AuthState>) => {
      deleteAuth(() => {
        if (window) {
          document.cookie = cookie.serialize(tokenHeaderName, "", { path: "/" })
        }
      })
      return onFailure(state)
    },
  }),
  ...pender({
    type: Types.SIGN_UP,
    onSuccess,
  }),
  ...pender({
    type: Types.UPDATE,
    onSuccess,
  }),
}, initialState);
