import { Search } from 'history';
import * as Pages from 'pages';
import queryString from 'query-string';
import { match } from 'react-router';
import { RouteConfig } from 'react-router-config';
import * as articleActions from 'redux/modules/articles';
import * as consultantActions from 'redux/modules/consultants';
import * as firstColumnActions from 'redux/modules/firstColumn';
import * as faqActions from 'redux/modules/faq';
import * as authActions from 'redux/modules/auth';
import * as settingActions from 'redux/modules/settings';

interface MatchParams {
  id?: string
}

const loadSettings = () => settingActions.load();

const showLoadData = typeof window === "undefined" ? undefined : (meta: match<MatchParams>) => articleActions.show(articleActions.ResourceName.consult_requests, meta.params.id!);

export interface ExtRouteConfig extends RouteConfig {
  exact?: boolean
  index?: boolean
  label?: string
  loadData?: (match: match<MatchParams>, search: Search) => any
  routes?: ExtRouteConfig[]
}

let routeConfig: ExtRouteConfig[] = [
  {
    path: '/',
    component: Pages.Home,
    exact: true,
    loadData: loadSettings,
  },
  {
    path: '/about',
    component: Pages.About.Index,
    label: "아트라상 소개",
    routes: [
      {
        path: '/about',
        component: Pages.About.Main,
        loadData: loadSettings,
        exact: true,
      },
      {
        path: '/about/consultants',
        component: Pages.About.Consultants,
        loadData: () => consultantActions.load(),
        label: "상담사 소개",
        exact: true,
        index: true,
      },
    ],
  },
  {
    path: '/users',
    component: Pages.Users.Index,
    label: '회원서비스',
    routes: [
      {
        path: '/users/sign_in',
        component: Pages.Users.SignIn,
        label: '로그인',
      },
      {
        path: '/users/sign_up',
        component: Pages.Users.SignUp,
        label: '회원가입',
      },
      {
        path: '/users/username',
        component: Pages.Users.Username,
        label: '아이디 찾기',
      },
      {
        path: '/users/password/new',
        component: Pages.Users.Password.New,
        label: '비밀번호 찾기',
      },
      {
        path: '/users/password/edit',
        component: Pages.Users.Password.Edit,
        label: '비밀번호 재설정',
      },
    ],
  },
  {
    path: '/consults',
    component: Pages.Consults.Index,
    label: "상담 신청",
    routes: [
      {
        path: '/consults/voice',
        component: Pages.Consults.Voice.Index,
        label: '음성 재회컨설팅',
        loadData: (_, search) => articleActions.load(articleActions.ResourceName.consult_requests, "?" + queryString.stringify({
          ...queryString.parse(search || ""),
          request_type: 'voice',
        })),
        exact: true,
        index: true,
      },
      {
        path: '/consults/text',
        component: Pages.Consults.Text.Index,
        label: '문서 재회컨설팅',
        loadData: (_, search) => articleActions.load(articleActions.ResourceName.consult_requests, "?" + queryString.stringify({
          ...queryString.parse(search || ""),
          request_type: 'text',
        })),
        exact: true,
        index: true,
      },
      {
        path: '/consults/voice/new',
        component: Pages.Consults.Voice.New,
        loadData: loadSettings,
        label: '음성 재회 컨설팅',
      },
      {
        path: '/consults/text/new',
        component: Pages.Consults.Text.New,
        loadData: loadSettings,
        label: '문서 재회 컨설팅',
      },
      {
        path: '/consults/voice/:id/edit',
        component: Pages.Consults.Edit,
        label: '음성 재회컨설팅',
        loadData: showLoadData,
      },
      {
        path: '/consults/voice/:id',
        component: Pages.Consults.Show,
        label: '음성 재회컨설팅',
        loadData: showLoadData,
      },
      {
        path: '/consults/text/:id/edit',
        component: Pages.Consults.Edit,
        label: '문서 재회컨설팅',
        loadData: showLoadData,
      },
      {
        path: '/consults/text/:id',
        component: Pages.Consults.Show,
        label: '문서 재회컨설팅',
        loadData: showLoadData,
      },
    ],
  },
  {
    path: '/columns',
    component: Pages.Columns.Index,
    label: "칼럼",
    routes: [
      {
        path: '/columns/first',
        component: Pages.Columns.First,
        label: '처음 오신 분을 위한 칼럼',
        loadData: () => firstColumnActions.load(),
        index: true,
      },
      {
        path: '/columns/common',
        component: Pages.Columns.Common.Index,
        label: '재회 칼럼',
        loadData: (match, search) => articleActions.load(articleActions.ResourceName.common_columns, search),
        exact: true,
        index: true,
      },
      {
        path: '/columns/client',
        component: Pages.Columns.Client.Index,
        label: '내담자 전용 칼럼',
        loadData: (match, search) => articleActions.load(articleActions.ResourceName.client_columns, search),
        exact: true,
        index: true,
      },
      {
        path: '/columns/common/:id',
        component: Pages.Columns.Common.Show,
        label: '재회 칼럼',
        loadData: (match) => articleActions.show(articleActions.ResourceName.common_columns, match.params.id!),
      },
      {
        path: '/columns/client/:id',
        component: Pages.Columns.Client.Show,
        label: '내담자 전용 칼럼',
        loadData: (match) => articleActions.show(articleActions.ResourceName.client_columns, match.params.id!),
      },
    ],
  },
  {
    path: '/reviews',
    component: Pages.Reviews.Index,
    label: "후기",
    routes: [
      {
        path: '/reviews/common',
        component: Pages.Reviews.Common.Index,
        label: '재회상담후기',
        loadData: (_, search) => articleActions.load(articleActions.ResourceName.reviews, search),
        exact: true,
        index: true,
      },
      {
        path: '/reviews/common/new',
        component: Pages.Reviews.New,
        label: '재회상담후기',
      },
      {
        path: '/reviews/common/:id',
        component: Pages.Reviews.Common.Show,
        label: '재회상담후기',
        loadData: (match) => articleActions.show(articleActions.ResourceName.reviews, match.params.id!),
      },
      {
        path: '/reviews/best',
        component: Pages.Reviews.Best.Index,
        label: '베스트 후기',
        loadData: (_, search) => articleActions.load(articleActions.ResourceName.best_reviews, search),
        exact: true,
        index: true,
      },
      {
        path: '/reviews/best/:id',
        component: Pages.Reviews.Best.Show,
        label: '베스트 후기',
        loadData: (match) => articleActions.show(articleActions.ResourceName.best_reviews, match.params.id!),
      },
    ],
  },
  {
    path: '/service',
    component: Pages.Service.Index,
    label: "문의·질문",
    routes: [
      {
        path: '/service/faq',
        component: Pages.Service.FAQ,
        label: 'FAQ',
        index: true,
        loadData: (_, search) => faqActions.load(search),
      },
      {
        path: '/service/contact',
        component: Pages.Service.Contact,
        label: '문의하기',
        index: true,
      },
    ],
  },
  {
    path: '/mypage',
    component: Pages.Mypage.Index,
    label: "마이페이지",
    routes: [
      {
        path: '/mypage/edit',
        component: Pages.Mypage.Edit,
        label: "회원정보 보기",
        index: true,
        exact: true,
        loadData: () => authActions.validate(),
      },
      {
        path: '/mypage/articles',
        component: Pages.Mypage.Articles,
        label: "작성글 보기",
        index: true,
        exact: true,
        loadData: () => authActions.validate(),
      },
    ],
  },
  {
    path: '/intra',
    component: Pages.Intra.Index,
    label: "비밀게시판",
    routes: [
      {
        path: '/intra/secrets',
        component: Pages.Intra.Secrets.Index,
        label: '',
        loadData: (_, search) => articleActions.load(articleActions.ResourceName.secrets, search),
        exact: true,
      },
      {
        path: '/intra/secrets/new',
        component: Pages.Intra.Secrets.New,
        label: '',
        exact: true,
      },
      {
        path: '/intra/secrets/:id',
        component: Pages.Intra.Secrets.Show,
        label: '',
        loadData: (match) => articleActions.show(articleActions.ResourceName.secrets, match.params.id!),
      },
    ],
  },
  {
    path: '/term/service',
    component: Pages.Terms.Service,
    loadData: loadSettings,
  },
  {
    path: '/term/privacy',
    component: Pages.Terms.Privacy,
    loadData: loadSettings,
  },
];

if (process.env.NODE_ENV === 'development') {
  const addRandomKey = (listOfConfig: ExtRouteConfig[]): ExtRouteConfig[] => { // For hot-reload
    return listOfConfig.map((conf) => {
      const newConf = {
        ...conf,
        key: Math.random(),
      };
      if (conf.routes) {
        newConf.routes = addRandomKey(conf.routes);
      }
      return newConf;
    });
  };

  routeConfig = addRandomKey(routeConfig);
}

export default routeConfig;
