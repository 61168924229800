import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader';
import cookie from 'cookie';

import App from 'App';
import store from 'redux/store';
import { register, tokenHeaderName } from 'utils/axiauth';
import NavDataLoader from './NavDataLoader';

register(
  () => cookie.parse(document.cookie)[tokenHeaderName],
  (token) => {
    document.cookie = cookie.serialize(tokenHeaderName, token, { path: "/" });
  },
);

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

window.addEventListener('popstate', function (event) {
  if (isIOS && isSafari) {
    window.location.reload();
  }
});

let AppClient = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <NavDataLoader>
          <App/>
        </NavDataLoader>
      </Provider>
    </BrowserRouter>
  );
};

if (process.env.NODE_ENV === 'development') {
  AppClient = hot(module)(AppClient);
}

export default AppClient;
