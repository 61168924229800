import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const Service = require('axios-middleware').Service;

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

export const tokenHeaderName = 'access-token';

const isServer = typeof window === "undefined";

const middleware: {
  onRequest?: any,
  onResponse?: any,
  onResponseError?: any,
} = {};

const service = new Service(axios);

export const register = (getCookie: () => string, setCookie: (value: string) => void) => {
  const onResponse = (response: AxiosResponse) => {
    const token = response.headers[tokenHeaderName];
    if (!token) return;

    if (!isServer) {
      axios.defaults.headers.common[tokenHeaderName] = token;
    }
    setCookie(token);
  };

  middleware.onRequest = (config: AxiosRequestConfig) => {
    if (config.headers[tokenHeaderName]) {
      return config;
    }
    const token = axios.defaults.headers.common[tokenHeaderName];
    if (!token) {
      const storedToken = getCookie();
      if (!isServer) {
        axios.defaults.headers.common[tokenHeaderName] = storedToken;
      }
      config.headers[tokenHeaderName] = storedToken;
    } else if (!config.headers[tokenHeaderName]) {
      config.headers[tokenHeaderName] = token;
    }
    return config;
  };
  if (!isServer) {
    middleware.onResponse = (response: AxiosResponse) => {
      onResponse(response);
      return response;
    };
    middleware.onResponseError = (error: AxiosError) => {
      if (error.response && error.response.status !== 401) onResponse(error.response);
      throw error;
    }
  }

  if (!service.has(middleware)) {
    service.register(middleware);
  }
};

export const deleteAuth = (setCookie: () => void) => {
  if (!isServer) {
    axios.defaults.headers.common[tokenHeaderName] = "";
  }
  setCookie();
};
