import React, { Component, FC } from 'react'
import { withRouter, Route, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch, compose } from 'redux';
import { Location } from 'history';

import loadBranchData from 'utils/loadBranchData'

interface Prop extends RouteComponentProps {
  dispatch: Dispatch,
}

interface State {
  previousLocation?: Location
}

class NavDataLoader extends Component<Prop, State> {
  state = {
    previousLocation: undefined
  }

  componentDidMount() {
    const { location, dispatch } = this.props
    loadBranchData(location.pathname, location.search, dispatch)
  }

  async componentWillReceiveProps(nextProps: Prop) {
    const { location, dispatch } = this.props
    const navigated = nextProps.location !== location

    if (navigated) {
      this.setState({previousLocation: location})
      await loadBranchData(nextProps.location.pathname, nextProps.location.search, dispatch)
      this.setState({previousLocation: undefined})
    }
  }

  render() {
    const { children, location } = this.props
    const { previousLocation } = this.state

    return (
      <Route
        location={previousLocation || location}
        render={() => children}
      />
    )
  }
}

export default compose<FC>(
  withRouter,
  connect()
)(NavDataLoader)