import routes, { ExtRouteConfig } from 'config/routes';
import { matchRoutes } from 'react-router-config';
import { match } from 'react-router';
import { Search, Path } from 'history';
import { Dispatch } from 'redux';

const loadBranchData = (url: Path, search: Search, dispatch: Dispatch) => {
  const branch = matchRoutes(routes, url);

  const promises = branch.map(({ route, match }: { route: ExtRouteConfig, match: match }) => {
    return route.loadData
      ? dispatch(route.loadData(match, search))
      : Promise.resolve(null);
  });

  return Promise.all(promises);
};

export default loadBranchData;
