import Img from 'components/Img'
import React, { FC, useState } from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import styled from 'styled-components'
import Link from './Link'
import links from './links'
import DesktopUtils from './DesktopUtils'
import { media } from 'styles/mixins'
import Drawer from 'components/Drawer'
import { connect } from "react-redux"
import { StoreState } from "../../redux/modules"
import { blue } from "../../config/colors"
import { signOut } from 'redux/modules/auth'

const MobileScreen = require('react-responsive-redux').MobileScreen
const DesktopScreen = require('react-responsive-redux').DesktopScreen

interface Props extends RouteComponentProps {
  userSignedIn: boolean
  signOut: () => void
}

const Nav: FC<Props> = ({ userSignedIn, signOut, history }) => {
  const [drawed, setDrawed] = useState(false)
  const linkComps = links.map(({ to, sublinks, label }) => (
    <Link key={to} to={to} sublinks={sublinks} onClick={() => setDrawed(false)}>{label}</Link>
  ))

  const onSignOut = async () => {
    await signOut();
    window.alert("로그아웃 되었습니다.");
  }

  return (
    <>
      <DesktopScreen>
        <Container>
          <div>
            {LogoComp}
            {linkComps}
          </div>
          <DesktopUtils/>
        </Container>
      </DesktopScreen>
      <MobileScreen>
        <Container>
          {LogoComp}
          <BurgerTrigger onClick={(e) => {
            e.preventDefault()
            setDrawed(true)
          }}>
            <Img
              source={[
                require('images/icons/burger.png'),
                require('images/icons/burger@2x.png'),
                require('images/icons/burger@3x.png'),
              ]}
              alt="="
            />
          </BurgerTrigger>
          <Drawer
            drawed={drawed}
            onClose={() => setDrawed(false)}
          >
            <MobileLinksContainer>
              <div>
                {LogoComp}
                {linkComps}
              </div>
              <DesktopUtils onClick={() => setDrawed(false)}/>
            </MobileLinksContainer>
          </Drawer>
          {userSignedIn ? (
            <MobileSignButton
              onClick={onSignOut}
            >로그아웃</MobileSignButton>
          ) : (
            <MobileSignButton
              onClick={() => history.push("/users/sign_in")}
            >로그인</MobileSignButton>
          )}
        </Container>
      </MobileScreen>
    </>
  )
}

const Container = styled.div`
height: 80px;
padding-left: 10vw;
padding-right: 10vw;
flex-direction: row;
justify-content: space-between;
align-items: center;
position: relative;
${media.mobile`
height: 50px;
justify-content: center;
`}
> div {
  flex-direction: row;
  align-items: center;
  &:first-child {
    align-self: stretch;
  }
}
`
const Logo = styled(Img)`
width: 123px;
margin-right: 34px;
${media.mobile`
width: 99px;
margin-right: 0;
`}
`
const BurgerTrigger = styled.a`
position: absolute;
left: 0;
top: 0;
bottom: 0;
padding: 0 1rem;
display: flex;
align-items: center;
justify-content: center;
> img {
  height: 18px;
}
`
const MobileLinksContainer = styled.div`
padding: 30px 33px 0 25px;
justify-content: space-between;
${Logo} {
width: 123px;
margin-bottom: 27px;
}
`

const MobileSignButton = styled.a`
color: ${blue};
font-size: 14px;
font-weight: bold;
position: absolute;
right: 1rem;
`

const LogoComp = (
  <NavLink to="/">
    <Logo
      source={[
        require('images/logo.png'),
        require('images/logo@2x.png'),
        require('images/logo@3x.png'),
      ]}
      alt="atrasan"
    />
  </NavLink>
)

const Connected = compose<FC>(
  withRouter,
  connect((state: StoreState) => ({
    userSignedIn: state.auth.userSignedIn,
  }), ({ signOut }))
)(Nav)

export default Connected
