import styled from 'styled-components'
import { media } from 'styles/mixins';

const Layout = styled.div`
padding-left: 13vw;
padding-right: 13vw;
${media.mobile`
padding-left: 2rem;
padding-right: 2rem;
`}
`

export default Layout
