import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Layout } from 'components/Styled';
import { connect } from 'react-redux';
import { StoreState } from 'redux/modules';
import Img from './Img';
import { media } from 'styles/mixins';
import { adminLevels } from "../config/constants";
import nl2br from "../utils/nl2br";

interface Props {
  userLevel: number
  content: string
}

const Footer: FC<Props> = ({ userLevel, content }) => {
  const [hidden, setHidden] = useState<{ label: string, to: string }>();
  const isAdmin = userLevel && adminLevels.includes(userLevel);

  useEffect(() => {
    (async () => {
      if (isAdmin) {
        const module = await import("../utils/hiddenImport");
        setHidden(module.hiddenFooterLink);
      }
    })();
  });

  return (
    <Container>
      <div>
        <Logo
          source={[
            require('images/logo-footer.png'),
            require('images/logo-footer@2x.png'),
            require('images/logo-footer@3x.png'),
          ]}
          alt="atrasan"
        />
        <P>
          {content && nl2br(content)}
        </P>
        <Row>
          <A to="/about">아트라상 소개</A>
          <A to="/consults/voice">상담신청</A>
          <A to="/service/contact">문의사항</A>
          <A to="/term/privacy">개인정보보호정책</A>
          <A to="/term/service">이용약관</A>
          {isAdmin && hidden && (
            <A to={hidden.to}>{hidden.label}</A>
          )}
        </Row>
      </div>
      <div>
      </div>
    </Container>
  );
};

const Container = styled(Layout)`
height: 220px;
background-color: #f1f1f1;
padding-bottom: 35px;
flex-direction: row;
justify-content: space-between;
> div {
  justify-content: flex-end;
}
`;
const P = styled.p`
font-size: 12px;
color: rgba(0,0,0,0.45);
margin-bottom: 32px;
${media.mobile`
font-size: 9px;
`}
a {
  font-size: 12px;
  color: rgba(0,0,0,0.45);
  ${media.mobile`
  font-size: 9px;
  `}
}
`;
const A = styled(P.withComponent(Link))`
margin-bottom: 0;
line-height: 1;
border-left: none;
padding-left: 0;
margin-left: 0;
& + & {
  border-left: solid 1px rgba(0,0,0,0.45);
  padding-left: 1rem;
  margin-left: 1rem;
  ${media.mobile`
  padding-left: 6px;
  margin-left: 6px;
  `}
}
`;
const Row = styled.div`
flex-direction: row;
`;
const Logo = styled(Img)`
width: 129px;
margin-bottom: 22px;
`;

export default connect((state: StoreState) => ({
  userLevel: state.auth.resource && state.auth.resource.user_level,
  content: state.settings.footer_content,
}))(Footer);
