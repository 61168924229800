const links = [
  {
    to: '/about',
    sublinks: [
      { label: "아트라상 소개", to: "/about" },
      { label: "상담사 소개", to: "/about/consultants" },
    ],
    label: "아트라상 소개"
  },
  {
    to: "/columns/first",
    sublinks: [
      { label: "처음 오신 분 칼럼", to: "/columns/first" },
      { label: "재회상담 칼럼", to: "/columns/common" },
      { label: "내담자 전용 칼럼", to: "/columns/client" },
    ],
    label: "칼럼",
  },
  {
    to: "/consults/voice",
    sublinks: [
      { label: "음성 재회컨설팅", to: "/consults/voice" },
      { label: "문서 재회컨설팅", to: "/consults/text" },
    ],
    label: "상담신청",
  },
  {
    to: "/reviews/common",
    sublinks: [
      { label: "재회상담후기", to: "/reviews/common" },
      { label: "베스트 후기", to: "/reviews/best" },
    ],
    label: "상담후기",
  },
  {
    to: "/service/faq",
    sublinks: [
      { label: "FAQ", to: "/service/faq" },
      { label: "문의하기", to: "/service/contact" },
    ],
    label: "문의",
  },
]

export default links