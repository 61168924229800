import axios from 'axios'
import { HOST, SETTINGS } from 'config/api'
import { Action, createAction, handleActions } from 'redux-actions'
import { pender } from 'redux-pender'
import * as Types from 'redux/actionTypes/settings'

export interface Setting {
  _id: string
  key: string
  raw: string
}

export interface SettingState {
  [key: string]: string
}

export const load = createAction(Types.LOAD, () => axios.get(HOST+SETTINGS.COLLECTIONS))

const initialState = {}

export default handleActions({
  ...pender({
    type: Types.LOAD,
    onSuccess: (state: SettingState, action: Action<{data: Setting[]}>) => {
      const settings = action.payload.data
      const newState: SettingState = {}
      settings.forEach((setting) => {
        newState[setting.key] = setting.raw
      })
      return newState
    },
  })
}, initialState)
