let HOST: string = process.env.REACT_APP_API_HOST || "http://localhost:8000/v1";
// let HOST: string = "http://localhost:8000/v1"
let ADMIN_HOST: string = process.env.REACT_APP_ADMIN_HOST || "http://localhost:8001/admin";

// if (typeof window === 'undefined') {
//   HOST = HOST.replace('localhost', 'api');
// }

export { HOST, ADMIN_HOST };

export const adminLevels = [99, 80];
