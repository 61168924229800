import React, { FC, useRef } from 'react';
import { renderRoutes } from 'react-router-config';

import routes from 'config/routes';
import Nav from 'components/Nav';
import AuthManager from 'components/AuthManager';
import Footer from 'components/Footer';
import ScrollToTop from 'components/ScrollToTop';
import ScrollNav from "./components/ScrollNav";
import Helmet from "react-helmet";

const App: FC = () => {
  const top = useRef<HTMLDivElement>(null);
  const bottom = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={top}/>
      <Helmet>
        <title>국내 독보적 1위 재회상담, 재회컨설팅, 연애상담 전문 법인 그룹 - 아트라상</title>
      </Helmet>
      <AuthManager/>
      <ScrollToTop/>
      <Nav/>
      {renderRoutes(routes)}
      <Footer/>
      <ScrollNav top={top} bottom={bottom}/>
      <div ref={bottom}/>
    </>
  );
};

export default App;
