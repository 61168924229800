import { FC, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToTop: FC<RouteComponentProps> = ({ location, history }) => {
  const { pathname, search } = location;

  useEffect(() => {
    if (history.action === "PUSH") {
      window.scrollTo(0, 0);
    }

  }, [pathname, search]);

  return null;
};

export default withRouter(ScrollToTop);
