import cookie from 'cookie';
import { useEffect, FC } from 'react';
import { connect } from 'react-redux';
import { validate } from 'redux/modules/auth';
import { tokenHeaderName } from 'utils/axiauth';

interface Prop {
  validate: typeof validate
}

const AuthManager: FC<Prop> = ({validate}) => {
  useEffect(() => {
    if (cookie.parse(document.cookie)[tokenHeaderName]) {
      validate()
    }
  }, [])
  return null
}

export default connect(null, { validate })(AuthManager)
