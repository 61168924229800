import axios from 'axios'
import { FAQ, HOST } from 'config/api'
import { List, Record } from 'immutable'
import { Action, createAction, handleActions } from 'redux-actions'
import { pender } from 'redux-pender'
import * as Types from 'redux/actionTypes/faq'

export interface Faq {
  _id: string
  title: string
  content: string
}

export interface FaqList {
  docs: List<Faq>
  total?: number
  limit?: number
  page?: number
  pages?: number
}

export interface FaqState {
  list: FaqList
}

const FaqListRecord = Record<FaqList>({
  docs: List([]),
  total: undefined,
  limit: undefined,
  page: 1,
  pages: undefined,
})

const FaqStateRecord = Record<FaqState>({
  list: FaqListRecord()
})

export const load = createAction(Types.LOAD, (paramsString: string = "") => axios.get(HOST+FAQ.COLLECTIONS+paramsString))

const initialState = FaqStateRecord()

export default handleActions({
  ...pender({
    type: Types.LOAD,
    onSuccess: (state: Record<FaqState>, action: Action<{data: FaqList}>) => state.set('list', FaqListRecord(action.payload.data)),
  })
}, initialState)