import { Map, fromJS, Record, RecordOf } from 'immutable';
import { createAction, handleActions, Action } from 'redux-actions';
import { pender } from 'redux-pender';
import axios from 'axios';

import { HOST, FIRST_COLUMNS } from 'config/api';
import * as Types from 'redux/actionTypes/firstColumn';
import { ArticleShow } from './articles';

export interface FirstColumn extends ArticleShow {
}

const FirstColumnRecord = Record<FirstColumn>({ _id: '', title: '', content: '', createdAt: '' });

export interface FirstColumnState {
  show: RecordOf<FirstColumn>,
}

const FirstColumnStateRecord = Record<FirstColumnState>({
  show: Record<FirstColumn>({ _id: "", title: "", content: "", createdAt: "" })(),
});

export const load = createAction(Types.LOAD, () => axios.get(HOST + FIRST_COLUMNS.COLLECTIONS));

const initialState = FirstColumnStateRecord();

export default handleActions({
  ...pender({
    type: Types.LOAD,
    onSuccess: (state: Record<FirstColumnState>, action: Action<{ data: FirstColumn }>) => state.set('show', FirstColumnRecord(action.payload.data)),
  }),
}, initialState);
